import React, { useEffect, useState } from 'react';
import styles from './addOrganization.module.css';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../../../../../inputs/input.js'
import InputSelect from '../../../../../../../../inputs/inputSelect.js'
import InputTextArea from '../../../../../../../../inputs/inputTextArea';

import { 
    setPopupAddOrganization, 
    setPopupChooseOrganization, 
    organizationPost, 
    organizationPut, 
    organizationDelete, 
    findKoatuu, 
    organizationsListGet,

 } from '../../../../../../../../../redux/actions/organizations/organizations';

import { objectGetUaIdentifiersList, objectGet } from '../../../../../../../../../redux/actions/object/object';
import { checkProp, isMongo } from '../../../../../../../../../utils/check';
import { getDictionaryByType, getUserOrganizations } from '../../../../../../../../../utils/endPoints';

const AddOrganizationNEW = ({data, setData, close, ...props}) => {

    const dispatch = useDispatch();
    const token = useSelector(state => state.start.token);
    const object = useSelector(state => state.start.objectGet);
    const koatuuList = useSelector(state => state.start.koatuuList);
    const regionsList = useSelector(state => state.start.regionList);

    const organizationUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/user/profile/organizations`;

    const objectUaIdentifiersList = useSelector(state => state.start.objectUaIdentifiersList);
    const urlUaIdentifiersList = getDictionaryByType('ua_identifiers');

    const organizationsGetList = useSelector(state => state.start.organizationsGetList);
    const urlOrganizationsList = getUserOrganizations;

    const [keyword, setKeyword] = useState('');

    const [name, setName] = useState(checkProp(data, 'name') ? isMongo(data.name) : null);
    const [identifier, setIdentifier] = useState( !!data?.identifier?.identifier ? data.identifier.identifier : '' );
    const [schemeIdentifier, setSchemeIdentifier] = useState( !!data?.identifier?.scheme ? data.identifier.scheme : null );
    // const [schemeIdentifierLegalName, setSchemeIdentifierLegalName] = useState( !!data?.identifier?.scheme?.legalName ? data.identifier.scheme.legalName : null );
    const [koatuu, setKoatuu] = useState( data?.address?.addressIdentifier?.identifier ? data.address.addressIdentifier.identifier : null );
    const [address, setAddress] = useState(checkProp(data, 'address') && checkProp(data.address, 'streetAddress') ? isMongo(data.address.streetAddress)  : null);
    const [locality, setLocality] = useState(checkProp(data, 'address') && checkProp(data.address, 'locality') ? isMongo(data.address.locality) : null);
    const [postalCode, setCode] = useState(checkProp(data, 'address') && checkProp(data.address, 'postalCode') ? data.address.postalCode : null);
    const [region, setRegion] = useState(checkProp(data, 'address') && checkProp(data.address, 'region') ? data.address.region : null);
    const [fullName, setFullName] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'name') ? isMongo(data.contactPoint.name) : null); 
    const [email, setEmail] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'email') ? data.contactPoint.email : null);
    const [phone, setPhone] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'telephone') ? data.contactPoint.telephone : null);
    const [fax, setFax] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'faxNumber') ? data.contactPoint.faxNumber : null);
    const [web, setWeb] = useState(checkProp(data, 'contactPoint') && checkProp(data.contactPoint, 'url') ? data.contactPoint.url : null);
    const [info, setInfo] = useState(checkProp(data, 'representativeInfo') ? data.representativeInfo : null);

    const [errorLocality, setErrorLocality] = useState('');

    // useEffect(() => {
    //     searchKoatuu(keyword);
    // }, [keyword]);

    useEffect(() => {
        if(objectUaIdentifiersList === null || objectUaIdentifiersList === undefined) {
            dispatch(objectGetUaIdentifiersList(urlUaIdentifiersList));
        }
    }, []);

    function createOrganization() {
        if (token !== null) {
            setErrorLocality('')
            if (locality.length < 3) {
                setErrorLocality('Значення занадто коротке. Повинно бути рівне 3 символам або більше.');
            } else {
                let body = JSON.stringify({
                    name: name ,
                    identifier: {
                        identifier: identifier === null || identifier === '' ? null : identifier,
                        scheme: schemeIdentifier === null || schemeIdentifier === '' ? null : schemeIdentifier,
                        legalName: name === null || name === '' ? null : name 
                    },
                    address: {
                        streetAddress: address === null || address === '' ? null : address,
                        locality: locality === '' ? null : locality,
                        region: region === null || region === '' ? null : region,
                        postalCode: postalCode === null || postalCode === '' ? null : postalCode,
                        countryName: 'Україна',
                    },
                    contactPoint: {
                        name: fullName === null || fullName === '' ? null : fullName,
                        email: email === null || email === '' ? null : email,
                        telephone: phone === null || phone === '' ? null : phone,
                        faxNumber: fax === null || fax === '' ? null : fax,
                        url: web === null || web === '' ? null : web
                    },
                    representativeInfo: info
                });
                let action = function() {
                    dispatch( organizationsListGet(urlOrganizationsList, token.access_token) );
                }
                if ( !data ) dispatch( organizationPost(organizationUrl, token.access_token, body, action));
                else dispatch( organizationPut( `${organizationUrl}/${data.id}`, token.access_token, body, action));
                close()
            }
        }
    }

    return(
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <div className={styles.popupBody}>
                    <button className={styles.closeButton} 
                        onClick={ close }
                    >
                    </button>
                    <div className={styles.title}>{ !!data ? "Редагування" : "Додавання"} організації</div>
                    <Input 
                        value={ name } 
                        label="Повна юридична назва організації або ПІБ фізичної особи  *" 
                        onChange={ e => setName(e) }
                    />
                    <div className={styles.subtitle}>Ідентифікатори організації</div>
                    <InputSelect 
                        value={schemeIdentifier} 
                        onChange={ e => { 
                            setSchemeIdentifier(e.name)
                            // setSchemeIdentifierLegalName(e.description.legalNameUa)
                        }}
                        options={objectUaIdentifiersList} 
                        uaIdentifiers={true} 
                        label="Ідентифікатори організації або особи *" 
                    />
                    <Input 
                        value={identifier} 
                        label="Код ЄДРПОУ або ІПН або паспорт *" 
                        onChange={ e => setIdentifier(e) }
                    />
                    <div className={styles.subtitle}>Адреса організації</div>
                    <Input 
                        label="Країна  *" 
                        disabled={true} 
                        value="Україна" 
                    />
                    <InputSelect 
                        value={region} 
                        options={regionsList} 
                        label="Область *" 
                        onChange={ e => setRegion(e) }
                    />
                    <Input 
                        value={locality} 
                        label="Населений пункт *"
                        error={errorLocality} 
                        onChange={ e => setLocality(e) }
                    />
                    <Input 
                        value={address} 
                        label="Адреса *" 
                        onChange={ e => setAddress(e) }
                    />
                    <Input 
                        value={postalCode} 
                        label="Поштовий індекс"
                        onChange={(e) => {
                            if (e.length < 6){
                                setCode(e)
                            }
                        }}
                        reqiured
                    />

                    <div className={styles.subtitle}>Контактна інформація</div>
                    <Input 
                        value={fullName} 
                        label="ПІБ" onChange={ e => setFullName(e) }
                        reqiured
                    />
                    <Input 
                        value={email} 
                        label="Адреса електронної пошти" 
                        onChange={ e => setEmail(e) }
                        reqiured
                    />
                    <Input  
                        value={phone} 
                        placeholder="+38(0__)___-__-__" 
                        label="Номер телефону" 
                        onChange={(e) => {
                            if (e.length < 14) {
                                setPhone(e);
                            }
                        }}
                        reqiured
                    />
                    <Input 
                        value={fax} 
                        placeholder="+38(0__)___-__-__" 
                        label="Номер факсу" 
                        onChange={(e) => {
                            if (e.length < 14) {
                                setFax(e);
                            }
                        }}
                    />
                    <Input 
                        value={web} 
                        label="Веб адреса"
                        placeholder="http://www.google.com"
                        descr="Приклад: http://some.site.com" 
                        onChange={ e => setWeb(e) } 
                    />
                    <InputTextArea 
                        value={info} 
                        label="Інформація щодо підтвердження повноважень" 
                        onChange={ e => setInfo(e) } 
                    />
                    <div className={styles.buttonWrapper}>
                        <button 
                            className={styles.buttonCancel} 
                            onClick={ close }>
                            Скасувати
                        </button>
                        {props.type === 'update' ? <>
                            <button 
                                className={styles.buttonCreate}
                            >
                                Оновити
                            </button>
                        </> : <>
                            <button 
                                onClick={ () => createOrganization() }
                                className={styles.buttonCreate}
                            >
                                { data ? 'Оновити' : 'Створити' }
                            </button>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddOrganizationNEW;