import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendRedemptionDraft } from '../../../../../../utils/endPoints';
import Button from '../../../../../elements/buttons/button'
import Loader from '../../../../../loader/loader';
import UniPopUp from '../../../../../popups/uniPopUp/uniPopUp'
import { validateAsset } from './validation'

const SendAssetToProzoro =({ data, asset })=> {
    const token = useSelector(state => state.start.token)
    const userProfiles = useSelector(state => state.start.userProfiles)
    const navigate = useNavigate()

    const [popUpDisabled, setPopUpDisabled] = useState( false )
    const [api, setApi] = useState( { loader: false, data: '' } )
    const [errorsPopup, setErrorsPopup] = useState( 
        {   errors: [],
            state: false
        } )

    function sendFunction( announcement, asset ){
        if ( !!validateAsset( announcement, asset )) {
            setErrorsPopup( prev => ({ ...prev, state: true }) )
            setErrorsPopup( prev => ({ ...prev, errors: validateAsset( announcement, asset ) }) )
        } else {
            setApi( prev => ({...prev, loader: true }))
            fetch( sendRedemptionDraft( data.id ), {
                    method: 'GET',
                    headers: { 'Authorization': 'Bearer ' + token.access_token, 'Content-Type': 'application/json' }
                })
                .then( res => {
                    if ( res.ok ) {
                        setApi( prev => ({...prev, loader: false }))
                        setApi( prev => ({...prev, data: 'Пріоритетний викуп успішно опубліковано' }))
                        setApi( prev => ({...prev, ok: true }))
                    }
                    return res.json() 
                })
                .then( data => {
                    // let json = res.json()
                    //{\"documents\":[\"document with documentType=redemptionNotice is required\"]}"
                    if ( data?.message ) {
                        setApi( prev => ({...prev, loader: false }))
                        let tmp = []
                        tmp[0] = <div style={{fontWeight: '600'}}>Для публікації викупу необхідно:</div>
                        if(data.message[0]?.indexOf("{\"documents\":[\"document with documentType=redemptionNotice is required\"]}") !== -1){
                            tmp[tmp.length] = <div>- завантажити документ з типом "Рішення про приватизацію об'єкта шляхом викупу"</div>
                        }
                        if(data.message[0]?.indexOf("{\"documents\":[\"document with documentType=contractSigned is required\"]}") !== -1){
                            tmp[tmp.length] = <div>- завантажити документ з типом "Підписаний договір"</div>
                        }
                        if(data.message[0]?.indexOf("{\"contracts\":") !== -1){
                            if(data.message[0]?.indexOf("[{\"buyers\":") !== -1){
                                if(data.message[0]?.indexOf("\"identifier\":") !== -1){
                                    if(data.message[0]?.indexOf("Id required pattern ^[0-9]{8}$ with scheme value UA-EDR") !== -1){
                                        tmp[tmp.length] = <div>- Необхідно правильно вказати код ЄДРПОУ (або ІПН, або паспот)</div>
                                    }
                                }
                                if(data.message[0]?.indexOf("\"address\":") !== -1){
                                    if(data.message[0]?.indexOf("\"postalCode\"") !== -1){
                                        if(data.message[0]?.indexOf("String value did not match validation regex.") !== -1){
                                            tmp[tmp.length] = <div>- Необхідно правильно вказати індекс</div>
                                        }
                                    }
                                }
                                if(data.message[0].indexOf("\"contactPoint\":") !== -1){
                                    if(data.message[0].indexOf("\"email\"") !== -1){
                                        if(data.message[0].indexOf("Not a well-formed email address.") !== -1){
                                            tmp[tmp.length] = <div>- Необхідно правильно вказати адресу електронної пошти</div>
                                        }
                                    }
                                    if(data.message[0].indexOf("\"telephone\"") !== -1){
                                        if(data.message[0].indexOf("String value did not match validation regex.") !== -1){
                                            tmp[tmp.length] = <div>- Необхідно вказати номер телефону у форматі +380ХХХХХХХХХ</div>
                                        }
                                    }
                                    if(data.message[0].indexOf("\"faxNumber\"") !== -1){
                                        if(data.message[0].indexOf("String value did not match validation regex.") !== -1){
                                            tmp[tmp.length] = <div>- Необхідно вказати номер факсу у форматі +380ХХХХХХХХХ</div>
                                        }
                                    }
                                    if(data.message[0].indexOf("\"url\"") !== -1){
                                        if(data.message[0].indexOf("Not a well-formed URL.") !== -1){
                                            tmp[tmp.length] = <div>- Необхідно вказати адресу сайту у форматі https://хххххх</div>
                                        }
                                    }
                                }
                            }
                            if(data.message[0].indexOf("\"value\":") !== -1){
                                if(data.message[0].indexOf("\"amount\":") !== -1){
                                    if(data.message[0].indexOf("Require int or float type, but str found") !== -1){
                                        tmp[tmp.length] = <div>- необхідно внести Вартість договору</div>
                                    }
                                }
                            }
                        }
                        setApi( prev => ({...prev, data: <>{tmp}</>}))
                    }
                })
                .catch( error => {
                    setApi( prev => ({...prev, loader: false }))
                    setApi( prev => ({...prev, data: error }))
                })
        }
    }

    function disabledBtn( asset ){
        return false
    }


    if ( !!data?.objectId ) return null

    if ( api.loader ) return <Loader />
    if ( api.data ) return <UniPopUp 
                                type={1}
                                close={ ()=> {
                                    setApi( prev => ({...prev, data: '' }))
                                    if ( api.ok ) navigate('/asset/my_redemptions/')
                                }}
                                data={ api.data } 
                            />
    return (
        <>
            { popUpDisabled && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setPopUpDisabled(false) } 
                    data={'Заповніть всі обов\'язкові поля, додайте Об\'єкти та документи, щоб опублікувати об\'єкт'} 
                /> 
            }
            { errorsPopup.state && 
                <UniPopUp 
                    type={1}
                    close={ ()=> setErrorsPopup( prev => ({...prev, state: false})) }
                    data={
                        <>
                            <div  style={{fontWeight: '600'}}>Для публікації пріоритетного викупу необхідно:</div>
                            <ul style={{textAlign: 'left'}}>
                                { errorsPopup.errors.map( i => <li key={i.toString()}>{i}</li>) }
                            </ul>
                        </>
                    }
                /> 
            }
            {userProfiles !== null && userProfiles.isFinAuth === true
                ? <Button 
                    height={'50px'} width={'200px'} 
                    // disabled={ disabledBtn() }
                    onClick={ ()=> { disabledBtn( data ) ? setPopUpDisabled(true) : sendFunction( data, asset ) } }
                >
                    Опублікувати в Prozorro
                </Button>
                : null
            }
        </>
    )

}

export default SendAssetToProzoro