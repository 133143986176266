import React, { useState } from 'react';
import styles from './myExecutionTabItem.module.css';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { checkProp, isMongo, dateTransform } from '../../../../utils/check';
import { assetDelete } from '../../../../redux/asset/assetActions';
import { getMyAnnouncementDraft } from '../../../../utils/endPoints';
import { setExecutionStatus } from '../../execution/utils';

const ExecutionTabItem = ( { data, id } ) => {

    const token = useSelector(state => state.start.token);

    const [dropDomnMenu, setdropDomnMenu] = useState(false)
    const [objPopUp, setObjPopup] = useState(false)
    const navigate = useNavigate()

    function isEditable( data ){
        // let view = false
        if ( !!data && ( data.status === 'pending' || data.status === 'active' ) ) return true
        if ( !!data && data.status === 'pending' ){
            data.documents.forEach( i => { if ( i.documentType !== 'clarifications') return true })
        }
        // if ( !!data && !data.status ) view = true
       return false
    }

    function dropDowmMenu() {
        if (dropDomnMenu) {
            return <div className={styles.dropDown}>
                <div className={styles.dropdownContent}>
                    <ul>
                        { !!data.executionId && <li><Link to={ `/asset/execution/${data.executionId}` }> Переглянути </Link> </li> }
                        {(isEditable(data) && data?.executionId !== null && data?.executionId?.indexOf("RES") !== -1) &&
                            <li>
                                <Link to={`/asset/execution/edit/${data.id}`}
                                    onClick={ () => window.scrollTo(0, 0) }
                                >
                                    Робота з контрактингом
                                </Link>
                            </li>
                        }
                        {(isEditable(data) && data?.executionId !== null && data?.executionId?.indexOf("REL") !== -1) &&
                            <li>
                                <Link to={`/asset/execution/edit_lp/${data.id}`}
                                    onClick={ () => window.scrollTo(0, 0) }
                                >
                                    Робота з контрактингом
                                </Link>
                            </li>
                        }
                    </ul>
                </div>
                <div className={styles.dropdownBlock} onClick={()=> setdropDomnMenu(!dropDomnMenu)}></div>
            </div>
        }
        return null
    }

    const objectPopUp = ( items )=> { return (
         <div className={styles.popUpOn}>
                    <div className={styles.objectPopUp}>
                        <div className={styles.closeBtn} onClick={()=> setObjPopup(!objPopUp)}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={styles.closeBtnSvg} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                            </svg>
                        </div>
                        <div className={styles.popUpTitle}>
                            Контракти: 
                            {/* <span>(Кількість об'єктів: {data.assetItems.length})</span> */}
                        </div>
                            { items &&
                                <ol>
                                    {
                                    items.length > 0 ?
                                            items.map( i => <li key={i.id}>
                                                <span>{ i.contractNumber }</span>
                                                <div>{`Статус: ${i.status}`}</div>
                                                { `Строк дії: ${dateTransform(i.contractTime.dateFrom)} - ${dateTransform(i.contractTime.dateTill)}` }
                                                </li> )
                                        :
                                            <div className={styles.tableEmpty}>У Вас немає контрактів.</div>
                                    }
                                </ol>
                            }
                    </div>
                <div className={styles.popUpBlock} onClick={()=> setObjPopup(!objPopUp)}></div>
            </div>
        )}

    if( !data ) return (
        <div className={styles.objTabItem}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <div>Виникла помилка - дані відсутні</div>
                <div style={{color: 'red'}} onClick={ () => assetDelete( getMyAnnouncementDraft( id ), token.access_token, ()=> navigate('/asset/my_annoucements') )}> видалити </div>
            </div>
        </div>
    )

    return (
        <div className={styles.objTabItem}>
            <div className={styles.tdName}>
                <Link className={styles.aName} to={ !data.executionId ? `/asset/execution/edit/${data.id}` : `/asset/execution/${data.executionId}` }>
                    { !!data?.title ? isMongo(data.title) : 'Контрактинг' }
                </Link>
                { !!data.relatedEntities[0] &&
                    <div>Пов'язаний об'єкт:
                        <span style={{color: '#696974'}}>{ ` ${data.relatedEntities[0].objectId}` }</span>
                    </div>
                }
                <div style={{cursor: 'pointer'}} onClick={()=> setObjPopup(!objPopUp) }>Контракти: 
                    <span style={{color: '#696974'}}>{ ` ${data.contracts.length}` }</span>
                </div>
                { data?.executionId !== null && data?.executionId?.indexOf("RES") === 0
                    ? <div>Тип: <span style={{color: '#696974'}}>Мала приватизація</span></div>
                    : null
                }
                { data?.executionId !== null && data?.executionId.indexOf("REL") === 0
                    ? <div>Тип: <span style={{color: '#696974'}}>Велика приватизація</span></div>
                    : null
                }
            </div>
            <div className={styles.tdType}>
                <div style={{fontWeight: '600'}}>{ data.executionId }</div>
            </div>
            <div className={styles.tdStatus}>
                { setExecutionStatus(data.status) }</div>
            <div className={styles.properties}>
                { !!data?.datePublished && 
                    <>
                        <div>Дата публікації:</div>
                        <div style={{color: 'var(--darkGray)', fontWeight: '600'}}>{  dateTransform(data.datePublished, 'time') }</div>
                    </>
                }
                { ( !!data?.dateModified && ( data.dateModified !== data.datePublished ) ) && 
                    <>
                        <div>Дата зміни:</div>
                        <div style={{color: 'var(--darkGray)', fontWeight: '600'}}>{  dateTransform(data.dateModified, 'time') }</div>
                    </>
                }
            </div>
            <div className={styles.tdBtnWrap}>
                <div className={ dropDomnMenu ? styles.btnActive : styles.tdBtn } onClick={ ()=> setdropDomnMenu( !dropDomnMenu ) }>
                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="-11 1 46 4">
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                                <path className={styles.svgIcon} d="M5.33,2.67A2.67,2.67,0,1,0,2.67,5.34,2.66,2.66,0,0,0,5.33,2.67Z"/>
                                <path className={styles.svgIcon} d="M14.67,2.67A2.67,2.67,0,1,0,12,5.34,2.67,2.67,0,0,0,14.67,2.67Z"/>
                                <path className={styles.svgIcon} d="M24,2.67a2.67,2.67,0,1,0-2.67,2.67A2.66,2.66,0,0,0,24,2.67Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            { dropDowmMenu() }
            { objPopUp && objectPopUp( data.contracts ) }
        </div>
    )
}

export default ExecutionTabItem