import { checkProp } from "../../../../../../utils/check"
import contactPoint from "../../../../../elements/contactPoint/contactPoint";

const validation = {
    title: {
        test: ( a )=> {
            if( !checkProp(a, 'uk_UA') ) return false // текст може тут одразу?
            if( a.uk_UA.length < 1 ) return false
            return true
        },
        text: 'Заповніть назву об\'єкту',
    },
    description: {
        test: ( a )=> {
            if( !checkProp(a, 'uk_UA') ) return false // текст може тут одразу?
            if( a.uk_UA.length < 1 ) return false
            return true
        },
        text: 'Заповніть опис об\'єкту',
    },
    ownershipType: {
        test: ( a )=> {
            if (['state','municipal','compatible'].includes(a) === false ) return false
            return true
        },
        text: 'Оберіть тип власності об’єкту малої приватизації',
    },
    decisions: {
        test: ( a )=> {
            if ( a.length < 1 ) return false
            for ( let key in a) {
                if ( !a[key].decisionTitle ) return false
                if ( !a[key].decisionNumber ) return false
                if ( !a[key].decisionDate ) return false
            }
            return true
        },
        text: 'Додайте мінімум одне рішення',
    },
    assetHolder: {
        test: ( a )=> {
            if ( !a ) return false
            if ( !a?.representativeInfo ) return false
            if ( !a?.contactPoint ) return false
            if ( !a?.contactPoint?.telephone ) return false
            return true
        },
        text: 'Заповніть інформацію про балансоутримувача',
    },
    assetCustodian: {
        test: ( a )=> {
            if ( !a ) return false
            return true
        },
        text: 'Додайте інформацію про розпорядника',
    },
    assetItems: {
        test: ( a )=> {
            if ( !a || a.length < 1 ) return false
            for ( let key in a) {
                if ( !a[key]?.description?.uk_UA ) return false
                if ( !a[key]?.unit ) return false
                if ( !a[key]?.quantity ) return false
                // let id = a[key].classification.id[0] + a[key].classification.id[1]
            }
            return true
        },
        text: 'Додайте Об\'єкти до об\'єкта та заповніть всі обов\'якові поля',
    },
    // documents: {
    //     test: ( a )=> {
    //         if ( !a || a.length < 1 ) return false
    //         // let img = false
    //         for ( let key in a) {
    //             // if ( !a[key]?.documentType === 'illustration' ) img = true
    //             if ( !a[key]?.title?.uk_UA ) return false
    //         }
    //         // if ( !img ) return false
    //         return true
    //     },
    //     type: null,
    //     text: 'Додайте документи до об\'єкта, зображення та заповніть всі обов\'якові поля',
    // }
}

const validationLP = {
    title: {
        test: ( a )=> {
            if( !checkProp(a, 'uk_UA') ) return false // текст може тут одразу?
            if( a.uk_UA.length < 1 ) return false
            return true
        },
        text: 'Заповніть назву об\'єкту',
    },
    description: {
        test: ( a )=> {
            if( !checkProp(a, 'uk_UA') ) return false // текст може тут одразу?
            if( a.uk_UA.length < 1 ) return false
            return true
        },
        text: 'Заповніть опис об\'єкту',
    },
    ownershipType: {
        test: ( a )=> {
            if (['state','municipal','compatible'].includes(a) === false ) return false
            return true
        },
        text: 'Оберіть тип власності об’єкту малої приватизації',
    },
    decisions: {
        test: ( a )=> {
            if ( a.length < 1 ) return false
            for ( let key in a) {
                if ( !a[key].decisionTitle ) return false
                if ( !a[key].decisionNumber ) return false
                if ( !a[key].decisionDate ) return false
            }
            return true
        },
        text: 'Додайте мінімум одне рішення',
    },
    // assetHolder: {
    //     test: ( a )=> {
    //         if ( !a ) return false
    //         return true
    //     },
    //     text: 'Додайте інформацію про балансоутримувача',
    // },
    assetCustodian: {
        test: ( a )=> {
            if ( !a ) return false
            return true
        },
        text: 'Додайте інформацію про розпорядника',
    },
    assetItems: {
        test: ( a )=> {
            if ( !a || a.length < 1 ) return false
            for ( let key in a) {
                if ( !a[key]?.description?.uk_UA ) return false
                //if ( !a[key]?.unit ) return false
                //if ( !a[key]?.quantity ) return false
                // let id = a[key].classification.id[0] + a[key].classification.id[1]
            }
            return true
        },
        text: 'Додайте Об\'єкти до об\'єкта та заповніть всі обов\'якові поля',
    },
}

export function validateAsset( obj ){
    let errArr = []
    for( let key in validation ){
        if ( obj.hasOwnProperty(key) ){
            if ( !validation[key].test( obj[key] ) ) {
                errArr = [...errArr, validation[key].text ]
            }
        }
    }
    return errArr.length > 0 ? errArr : false
}

export function validateAssetLP( obj ){
    let errArr = []
    for( let key in validationLP ){
        if ( obj.hasOwnProperty(key) ){
            if ( !validationLP[key].test( obj[key] ) ) {
                errArr = [...errArr, validationLP[key].text ]
            }
        }
    }
    return errArr.length > 0 ? errArr : false
}