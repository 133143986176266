import React from 'react';
import {  useNavigate } from 'react-router-dom';

import styles from './errorPage.module.css';
import buttonStyles from './buttons.module.css';

import Img400 from './400.svg';
import Img403 from './403.svg';
import Img404 from './404.svg';
import Img500 from './500.svg';
import Img502 from './502.svg';
import Img503 from './503.svg';
import Img504 from './504.svg';
import Button from '../../elements/buttons/button';

const ErrorPage = ({ errorCode, pathname }) => {
    const Errors = {
        400: {
            title: "Йой!",
            text: ["Не хвилюйтеся, це лише помилка 400", "Сервер не зміг обробити Ваш запит"],
            image: Img400,
            hint: "Поверніться і спробуйте ще раз",
            buttons: {
                back: true,
                home: true,
                tryAgain: false,
            },
        },
        401: {
            title: "Йой!",
            text: ["Не хвилюйтеся, це лише помилка 401", "Треба переавторизуватися"],
            image: Img400,
            hint: "Поверніться і спробуйте ще раз",
            buttons: {
                back: true,
                home: true,
                tryAgain: false,
            },
        },
        403: {
            title: "Ой, лишенько!",
            text: ["Не хвилюйтеся, це лише помилка 403", "У Вас немає доступу до цієї сторінки"],
            image: Img403,
            hint: "Увійдіть у систему під відповідною роллю",
            buttons: {
                back: true,
                home: true,
                tryAgain: false,
            },
        },
        404: {
            title: "Ой, леле!",
            text: ["Не хвилюйтеся, це лише помилка 404", "Сторінки, яку ви шукаєте, немає"],
            image: Img404,
            hint: "Перевірте адресу сторінки, або поверніться на головну",
            buttons: {
                back: true,
                home: true,
                tryAgain: false,
            },
        },
        500: {
            title: "Ууух!",
            text: ["Не хвилюйтеся, це лише помилка 500", "Сервер має труднощі з роботою"],
            image: Img500,
            hint: "Ми намагаємося виправити проблеми. Спробуйте виконати дію через якийсь час",
            buttons: {
                back: false,
                home: true,
                tryAgain: true,
            },
        },
        502: {
            title: "Ооох!",
            text: ["Не хвилюйтеся, це лише помилка 502", "Зросло навантаження на сервері"],
            image: Img502,
            hint: "Ми намагаємося виправити проблеми. Спробуйте виконати дію через якийсь час",
            buttons: {
                back: false,
                home: true,
                tryAgain: true,
            },
        },
        503: {
            title: "Ааай!",
            text: ["Не хвилюйтеся, це лише помилка 503", "Зросло навантаження на сервері"],
            image: Img503,
            hint: "Ми намагаємося виправити проблеми. Спробуйте виконати дію через якийсь час",
            buttons: {
                back: false,
                home: true,
                tryAgain: true,
            },
        },
        504: {
            title: "Ееех!",
            text: ["Не хвилюйтеся, це лише помилка 504", "Зросло навантаження на сервері"],
            image: Img504,
            hint: "Ми намагаємося виправити проблеми. Спробуйте виконати дію через якийсь час",
            buttons: {
                back: false,
                home: true,
                tryAgain: true,
            },
        },
    };

    const navigate = useNavigate ();

    const pageContent = errorCode ? Errors[errorCode] : Errors[404];

  return (
    <div className={styles.wrap}>
        <h1 className={styles.h1Title}>{pageContent.title}</h1>
        <div>
            {pageContent.text.map( text => <p className={styles.text} key={text}>{text}</p> )}
        </div>
        <img className={styles.image} src={pageContent.image} />
        <div className={styles.hint}>{pageContent.hint}</div>
        <div className={buttonStyles.buttonWrapper}>
            <Button color={'gray'}
                    onClick={ pageContent.buttons.tryAgain ? ()=> navigate(pathname) : ()=> navigate(-1) }
            >
                { pageContent.buttons.tryAgain ? "Спробувати ще раз" : "Назад" }
            </Button>
            <Button color={'blue'} onClick={ ()=> navigate("/") }>На головну</Button>
        </div>
        <div className={styles.support}>
            Потрібна допомога? Звертайтеся до нашої техпідтримки: <a href="mailto:info@dto.com.ua">info@dto.com.ua</a>.
        </div>
    </div>
  );
}

export default ErrorPage;
